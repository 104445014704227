
export default

	props: block: Object

	computed:

		# Root classes
		classes: -> [
			"align-#{@block.textAlign}"
			@block.maxWidth || 'max-w-small' # Default for backwards compat
		]

